@font-face {
  font-family: Proxima-Nova-Thin;
  src: url('../fonts/Proxima-Nova-Thin.otf') format('opentype');
}

@font-face {
  font-family: Proxima-Nova-Thin-Italic;
  src: url('../fonts/Proxima-Nova-Thin-Italic.otf') format('opentype');
}

@font-face {
  font-family: Proxima-Nova-Regular;
  src: url('../fonts/Proxima-Nova-Regular.otf') format('opentype');
}

@font-face {
  font-family: Proxima-Nova-Bold;
  src: url('../fonts/Proxima-Nova-Bold.otf') format('opentype');
}

@font-face {
  font-family: Proxima-Nova-Extrabold;
  src: url('../fonts/Proxima-Nova-Extrabold.otf') format('opentype');
}

@font-face {
  font-family: Heebo-Regular;
  src: url('../fonts/Heebo-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Montserrat-Light;
  src: url('../fonts/Montserrat-Light.ttf') format('opentype');
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url('../fonts/Montserrat-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/Montserrat-Bold.ttf') format('opentype');
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Proxima-Nova-Regular;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

div#root {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: auto;
}

.snackbar-root {
  z-index: 9999 !important;
}

.Select.is-open {
  z-index: 9999 !important;
}

@page {
  size: 297mm 210mm;
}

@media print {
  html,
  body {
    height: auto !important;
    margin: auto;
    padding: 0;
    background-color: grey;
  }
  #rightDrawer {
    display: none;
  }
  .invisible-during-print {
    visibility: hidden;
  }
}

.CodeMirror {
  height: 100% !important;
  min-height: 300px !important;
}
